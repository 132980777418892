<!-- Error notification -->
<div
  elTestId="Tev3ggOusNGaJ_d6D3GIZ"
  *ngIf="errorMessage"
  class="a-notification -error"
  role="alert"
>
  <em
    elTestId="RB_9cWDcYx9RjJhH2HXDw"
    class="a-icon ui-ic-alert-error"
    title="Error"
  ></em>
  <div
    elTestId="Swlign_eGM6xDepvZktYz"
    class="a-notification__content"
    id="notificationContentError"
    [innerHTML]="'ddaap.messages.' + errorMessage | translate"
  ></div>
  <button
    elTestId="xzfDXz_4toiK_JuB_v5pl"
    tabindex="-1"
    type="button"
    class="a-button a-button--integrated -without-label"
    (click)="onCloseErrorNotification()"
  >
    <em
      elTestId="l0q9smsZcVbzhss5cnfnG"
      class="a-icon a-button__icon__notification__savingMessage ui-ic-close"
      title="close"
    ></em>
  </button>
</div>
